var site = site || {};

(function ($) {
  $(document).on('email_signup:success_but_signed_up', function () {
    Drupal.behaviors.popupOfferV1.launchPopupWindow('.js-popup-offer-signed-up');
    Drupal.behaviors.popupOfferV1.bindEvents();
  });

  $(document).on('email_signup:success_and_new', function () {
    Drupal.behaviors.popupOfferV1.launchPopupWindow('.js-popup-offer-thank-you');
    Drupal.behaviors.popupOfferV1.bindEvents();
  });

  Drupal.behaviors.popupOfferV1 = {
  // Inline template name that is grabbed and shown in pop-up.
    templateName: 'popup_offer_uk_v1',

    // Class name to be used for colorbox popup launch.
    colorBoxClassName: 'js-popup-offer-overlay-cbox',

    // Cookie name used to decide to show popup or not.
    cookieName: 'hide_popup_offer',

    // The four larger elements that are displayed at different
    // stages of the popup process.
    popupElements: {
      first: '.js-popup-offer-outer-wrap',
      thankYou: '.js-popup-offer-thank-you',
      signedUp: '.js-popup-offer-signed-up',
      terms: '.js-popup-offer-terms'
    },

    // Track whether or not the .js-popup-offer-terms element is showing.
    termsShowing: false,

    // Store which div class was showing when the terms were opened.
    elementShowing: '',

    // Store the original colorbox close method.
    originalColorboxCloseMethod: $.colorbox.close,

    // Given the way the terms & conditions are shown in the colorbox overlay,
    // the close function must be overwritten a bit:
    // If the flag for termsShowing is true, just hide the terms, don't close the box.
    // Otherwise, act normally, by calling the original close method.
    overwriteColorboxClose: function () {
      var self = this;

      $.colorbox.close = function () {
        if (self.termsShowing) {
          self.hideTerms();
        } else {
          self.originalColorboxCloseMethod();
        }
      };
    },

    // Determine if the popup offer should be shown, and return a boolean value.
    shouldShowPopupWindow: function () {
      var status = false;

      // Place for future code that controls where/when pop-up shouldn't occur.
      return status;
    },

    // Show the terms element and store what was being shown previously.
    showTerms: function (e) {
      var self = this;

      $(self.popupElements.terms).show();
      $(self.elementShowing).hide();
      self.termsShowing = true;
    },

    // Hide the .js-popup-offer-terms element and show previous element.
    hideTerms: function () {
      var self = this;

      $(self.popupElements.terms).hide();
      $(self.elementShowing).show();
      self.termsShowing = false;
    },

    // Make the RPC call for the email sign-up form in the pop-up.
    processForm: function () {
      var self = this;
      // Get all the fields in code because 'serialize' encodes bad emails and doesn't decode plus symbols.
      var formFields = [
        '_SUBMIT',
        'COUNTRY_ID',
        'LANGUAGE_ID',
        'PC_EMAIL_PROMOTIONS',
        'LAST_SOURCE',
        'ORIGINAL_SOURCE',
        'PC_EMAIL_ADDRESS'
      ];
      // Create an object to hold all the parameters and their values.
      var paramObj = {};

      // Loop through each field and get its value.
      $.each(formFields, function (index, value) {
        var formField = $('.js-popup-offer-sign-up input[name=' + value + ']');

        paramObj[value] = formField.val();
      });

      // Send the data via a JSON RPC call.
      generic.jsonrpc.fetch({
        method: 'rpc.form',
        params: [paramObj],
        onSuccess: function (jsonRpcResponse) {
          var response = jsonRpcResponse.getData();
          // Store returned boolean value for whether the user has already opted in the promotion before.
          var previouslyOptedInEmailPromotion = response.userinfo.previously_opted_in_email_promotion;
          // Store return boolean for still running the special offer codes.
          var specialOfferCodeStatus = response.userinfo.special_offer_code_status || 1;

          // If the user had not previously opted in AND the special offers are still running.
          if (!previouslyOptedInEmailPromotion && specialOfferCodeStatus) {
            $(self.popupElements.thankYou).show();
            self.elementShowing = self.popupElements.thankYou;
            // Else show that the user has already signed up for this offer.
          } else {
            $(self.popupElements.signedUp).show();
            self.elementShowing = self.popupElements.signedUp;
          }

          // Regardless, hide the original sign up form portion.
          $(self.popupElements.first).hide();
        },
        onFailure: function (jsonRpcResponse) {
        // Store messages and display them in error div element.
          var messages = jsonRpcResponse.getMessages();

          $('.js-popup-offer-error-messages').empty().html(messages[0].text).removeClass('hidden');
        }
      });
    },

    // Launch the popup window with the appropriate element visible.
    launchPopupWindow: function (elementToShow) {
      var self = this;

      // Set a default elementToShow just in case.
      if (elementToShow === undefined) {
        elementToShow = self.popupElements.first;
      }

      // Get the popup template.
      var templateHTML = site.template.get({
        name: self.templateName
      });

      // Launch the popup with colorbox.
      generic.overlay.launch({
        content: templateHTML,
        width: 511,
        height: 352,
        cssClass: self.colorBoxClassName
      });

      // Loop through all popupElements and only show the one specified
      // in the parameter elementToShow (or the default value).
      for (var elemKey in self.popupElements) {
        if (self.popupElements.hasOwnProperty(elemKey)) {
          var elemValue = self.popupElements[elemKey];

          if (elemValue === elementToShow) {
            $(elemValue).show();
            self.elementShowing = elemValue;
          } else {
            $(elemValue).hide();
          }
        }
      }
    },

    bindEvents: function () {
      var self = this;

      $('.js-popup-offer-footer a').click(function (e) {
        e.preventDefault();
        self.showTerms();
      });
      $('.js-popup-offer-submit').click(function (e) {
        e.preventDefault();
        self.processForm();
      });
    },

    attach: function (context, settings) {
      var self = this;
      var offerKey = '';
      // Check for a value for the session cookie.
      var hidePopupCookie = $.cookie(self.cookieName);

      // Create session cookie with value.
      $.cookie(self.cookieName, offerKey, { path: '/' });

      // If the session cookie does not exist, work on launching the popup.
      if (hidePopupCookie !== offerKey) {
      // If offer key is set with a value, check on its timing on the Perlgem side.
        if (offerKey.length > 0) {
          generic.jsonrpc.fetch({
            method: 'offer',
            params: [offerKey],
            onSuccess: function (r) {
              var offer = r.getValue();
              var isActive = offer && (offer.timing === 'active');

              // If active, then show.
              if (isActive && self.shouldShowPopupWindow()) {
                self.launchPopupWindow();
                self.overwriteColorboxClose();
                self.bindEvents();
              }
            },
            onFailure: function (r) {
            // Check to see if PG server is running? 
            }
          });
        }
        // Else offer key is left blank, and should just popup as normal.
        else {
          if (self.shouldShowPopupWindow()) {
            self.launchPopupWindow();
            self.overwriteColorboxClose();
            self.bindEvents();
          }
        }
      }
    // Else the session cookie does exist, and the popup should not launch.
    }
  };
})(jQuery);
